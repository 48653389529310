
import { defineComponent, ref } from "vue";
import { Search } from '@element-plus/icons-vue'
import SbomDataService from "@/services/SbomDataService";
import ResponseData from "@/types/ResponseData";
import { IsSelectArtifact, NoAssertionFormat, IsOpenEulerByProductName } from "@/utils"
import { mapGetters} from 'vuex';
import SbomVulnerabilityTable from '@/components/SbomVulnerabilityTable.vue'
import { vulSeverityList, formatVulSeverity } from "@/utils"
import { ElMessage } from 'element-plus';

const licenseList = [
  { label: '多license', prop: 'multiLicense' },
  { label: '无license', prop: 'noLicense' },
  { label: 'OSI/FSF认证license', prop: 'legalLicense' },
  { label: '非OSI/FSF认证license', prop: 'ilegalLicense' },
]

export default defineComponent({
  name: "sbom-packages-list",
  components: {
    SbomVulnerabilityTable
  },
  data() {
    return {
      packageName: "",
      isExactly: false,
      pageData: [],
      pageNum: ref(1),
      pageSize: ref(10),
      totalElements: ref(0),
      Search: Search,
      vluProps: [
        { label: '致命', prop: 'criticalVulCount' },
        { label: '高', prop: 'highVulCount' },
        { label: '中', prop: 'mediumVulCount' },
        { label: '低', prop: 'lowVulCount' },
        { label: '无风险', prop: 'noneVulCount' },
        { label: '未知', prop: 'unknownVulCount' },
      ],
      isOpenEuler: false,
      vulSeverityList,
      vulSeverity: this.$route.query.vulSeverity || '',
      licenseList,
      licenseType: this.$route.query.licenseType || '',
      vulnSeverity: '',
      packageId: '',
      showVulnDialog: false,
      licenseId: '',
      licenseIds: [] 
    };
  },
  computed:{
    ...mapGetters([
      'getProductName'
    ])
  },
  watch: {
    getProductName() {
      this.packageName = ''
      this.licenseId = ''
      this.search();
      this.formatIsOpeneuler();
      this.getLicenseIds()
    }
  },
  methods: {
    indexCounter(index: number) {
      return index + (this.pageNum - 1) * this.pageSize + 1;
    },

    handlePageChange(val: number) {
      this.retrievePackages();
    },

    handleSizeChange(val: number) {
      this.search()
    },
    search() {
      this.pageNum = 1
      this.retrievePackages();  
    },

    retrievePackages() {
      if (!IsSelectArtifact(true)) { 
        this.pageData = []
        return; 
      }

      let requestParam = new FormData()
      // requestParam.append('productName', (window as any).SBOM_PRODUCT_NAME);
      requestParam.append('productName', String(this.getProductName));
      requestParam.append('page', String(this.pageNum - 1));
      requestParam.append('size', String(this.pageSize));

      if (this.packageName) {
        requestParam.append('packageName', String(this.packageName))
        requestParam.append('isExactly', String(this.isExactly))
      }
      if(this.licenseType) {
        const licenseType = this.licenseType
        let prop: any = ''
        let val: any = true
        if(licenseType === 'legalLicense' || licenseType === 'ilegalLicense') {
          prop = 'isLegalLicense'
          val = licenseType === 'legalLicense'
        } else {
          prop = licenseType
          val = true
        }
        requestParam.append(prop, val)
      }
      if(this.vulSeverity) {
        requestParam.append('vulSeverity', String(this.vulSeverity))
      }
      if(this.licenseId) {
        requestParam.append('licenseId', String(this.licenseId))
      }
      SbomDataService.querySbomPackagesByPageable(requestParam)
        .then((response: ResponseData) => {
          this.pageData = response.data.content;
          this.totalElements = response.data.totalElements;
        })
        .catch((e: any) => {
          if (e.response && e.response.status == 500) {
            ElMessage({
              message: e.response.data,
              type: 'error'
            })
          }
          this.totalElements = 0
          this.pageData = []
        });
    },

    NoAssertionFormat,

    IsOpenEulerByProductName,
    openVulnDialog(row, item, num) {
			if(!item || num === 0) return
			this.vulnSeverity = formatVulSeverity(item.label)
			this.packageId = row.id
			this.showVulnDialog = true
    },
    formatIsOpeneuler() {
      this.isOpenEuler = this.getProductName && this.getProductName.includes('openEuler')
    },
    getLicenseIds() {
      if (!this.getProductName) { 
        return; 
      }
      this.licenseIds = []
      SbomDataService.queryProductStatistics(this.getProductName)
        .then((response: ResponseData) => {
          if(response.data) {
            const keys = Object.keys(response.data.licenseDistribution)
            let list:any = []
            keys.length && keys.map(item => {
              list.push({
                label: item,
                value: item,
              })
            })
            this.licenseIds = list
          }
        })
        .catch((e: any) => {
          if (e.response && e.response.status == 500) {
            ElMessage({
              message: e.response.data,
              type: 'error'
            })
          }
        });
    },
    goPath(item) {
      if(item.licenseUrl) {
        window.open(item.licenseUrl, '_blank')
      }
    }
  },
  mounted() {
    this.retrievePackages();
    this.formatIsOpeneuler();
    this.getLicenseIds();
  },
});
